import SortHelpers from "./SortHelpers";
import timezones from "./../../data/timezones.json";
import { getPaymentPlanText, getConditionalType } from "@/library/helpers/paymentPlan";

var objectMapper = require("object-mapper");

class AccessPlanHelpers {
  filter(plans, filters) {
    if (!filters) return plans;
    if (filters.search) {
      if (!filters.enableRegexSearch) {
        let search = filters.search.toLowerCase();
        plans = plans.filter((plan) => plan.searchable.toLowerCase().indexOf(search) > -1);
      } else {
        try {
          const search = new RegExp(filters.search, "");
          plans = plans.filter((plan) => plan.searchable.match(search));
        } catch (error) {
          console.log(error.message);
          plans = [];
        }
      }
    }

    if (filters.sort_direction_desc) plans.sort(SortHelpers.compareValues(filters.sort_by, "desc"));
    else plans.sort(SortHelpers.compareValues(filters.sort_by));

    if (filters.hasOwnProperty("planType") && filters.planType !== "all")
      plans = plans.filter((plan) => (filters.planType == "time-based" ? !!plan.params && !!plan.params.timezone : !plan.params.timezone));
    if (filters.hasOwnProperty("autostart") && filters.autostart !== "all") {
      plans = plans.filter((plan) => plan.params.hasOwnProperty("isAutoStart") && !!filters.autostart === !!plan.params.isAutoStart);
    }
    return plans;
  }
  getSearchable(originalPlan) {
    function mapUnless(unless) {
      if (!unless) return;
      const mapped_datas = [];
      const map = {
        condition: {
          key: "condition",
          transform: (v) => {
            const type = getConditionalType(v);
            return getPaymentPlanText({ type, values: v })
          },
        },
        "plan.hRate": {
          key: "hRate",
          transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
        },
        "plan.whRate": {
          key: "whRate",
          transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
        },
        "plan.startSessionCost": {
          key: "startSessionCost",
          transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
        },
        "plan.unless": {
          key: "unless",
          transform: (v) => mapUnless(v),
        },
      };
      for (let i = 0; i < unless.length; i++) {
        const datas = Object.values(objectMapper(unless[i], map));
        for (let j = 0; j < datas.length; j++) if (!mapped_datas.includes(datas[j])) mapped_datas.push(datas[j]);
      }
      return mapped_datas.flat();
    }

    const map = {
      description: "description",
      wallet_name: "wallet_name",
      "params.planType": "planType",
      "params.hRate": {
        key: "hRate",
        transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
      },
      "params.whRate": {
        key: "whRate",
        transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
      },
      "params.startSessionCost": {
        key: "startSessionCost",
        transform: (v) => (v == 0 || !v ? "0/FREE" : parseFloat(v).toFixed(2)),
      },
      "params.isAutoStart": {
        key: "autoStart",
        transform: (v) => (!!v ? "auto-start:enabled" : "auto-start:disabled"),
      },
      "params.timezone": {
        key: "timezone",
        transform: (v) => {
          const selectedTimezone = timezones.find((tm) => tm.value == v);
          return selectedTimezone ? selectedTimezone.text : null;
        },
      },
      "params.unless": {
        key: "unless",
        transform: (v) => mapUnless(v),
      },
    };
    let plan = JSON.parse(JSON.stringify(originalPlan));
    const mappedData = Object.values(objectMapper(plan, map)).flat();
    const searchable = [];
    for (let i = 0; i < mappedData.length; i++) if (!searchable.includes(mappedData[i])) searchable.push(mappedData[i]);
    return searchable.join("###");
  }
}

export default new AccessPlanHelpers();
