import SortHelpers from "./SortHelpers";

class SessionHelpers {
  filter(sessions, filters = {}) {
    if (filters.search) {
      if (!filters.enableRegexSearch) {
        let search = filters.search.toLowerCase();
        sessions = sessions.filter((session) => {
          const identifier = session.plug_identifier.toLowerCase();
          const user = session.username.toLowerCase();
          const description = session.device ? session.device.description.toLowerCase() : "";
          const start_time = session.starttime_formatted.toLowerCase();
          const last_updated = session.update_formatted.toLowerCase();
          const duration = session.duration.toLowerCase();
          const consumption = session.consumption.toLowerCase();
          const revenue = session.amount.toLowerCase();
          const plug_description = session.plug_description ? session.plug_description.toLowerCase() : "";
          return (
            identifier.includes(search) ||
            user.includes(search) ||
            description.includes(search) ||
            start_time.includes(search) ||
            last_updated.includes(search) ||
            duration.includes(search) ||
            consumption.includes(search) ||
            revenue.includes(search) ||
            plug_description.includes(search)
          );
        });
      } else {
        try {
          const search = new RegExp(filters.search, "");

          sessions = sessions.filter((session) => {
            const identifier = session.plug_identifier;
            const user = session.username;
            const description = session.device ? session.device.description : "";
            const plug_description = session.plug_description;
            const start_time = session.starttime_formatted;
            const last_updated = session.update_formatted;
            const duration = session.duration;
            const consumption = session.consumption;
            const revenue = session.amount;
            return (
              identifier.match(search) ||
              user.match(search) ||
              description.match(search) ||
              start_time.match(search) ||
              last_updated.match(search) ||
              duration.match(search) ||
              consumption.match(search) ||
              revenue.match(search) ||
              plug_description.match(search)
            );
          });
        } catch (error) {
          console.log(error.message);
          sessions = [];
        }
      }
    }

    if (filters.sort_by) {
      const sort_direction = filters.sort_direction_desc ? "desc" : "asc";
      if (["plug_identifier", "username", "plug_description"].includes(filters.sort_by)) sessions.sort(SortHelpers.compareValues(filters.sort_by, sort_direction));
      else if (["updated", "endtime"].includes(filters.sort_by))
        sessions = (filters.sort_direction_desc
          ? sessions.sort((a, b) => b[filters.sort_by] - a[filters.sort_by])
          : sessions.sort((a, b) => a[filters.sort_by] - b[filters.sort_by])
        ).sort((a, b) => b.statusCode - a.statusCode);
      else if (filters.sort_direction_desc) sessions.sort((a, b) => b[filters.sort_by] - a[filters.sort_by]);
      else sessions.sort((a, b) => a[filters.sort_by] - b[filters.sort_by]);
    } else sessions.sort(SortHelpers.compareValues("plug_identifier"));

    if (filters.hasOwnProperty("status") && filters.status !== "all") sessions = sessions.filter((o) => o.statusCode === parseInt(filters.status));
    if (filters.plug_identifier) sessions = sessions.filter((o) => o.plug_identifier === filters.plug_identifier);

    return sessions;
  }
  getStats(sessions) {
    let consumptionkWh = 0;
    let session = 0;
    let unique_users = [];
    let cost = 0;
    let duration = 0;
    for (let i = 0; i < sessions.length; i++) {
      const s = sessions[i];
      let updated = s.last_update_timestamp ? s.last_update_timestamp : s.starttime;
      consumptionkWh += s.total_consumption / 1000;
      session += 1;
      duration += s.endtime ? s.endtime - s.starttime : updated - s.starttime;
      cost += s.total_cost;
      if (!unique_users.includes(s.user_id)) unique_users.push(s.user_id);
    }

    let user = unique_users.length;

    let revenues = sessions
      .reduce((acc, val) => {
        let o = acc.filter((obj) => obj.owner_wallet_id === val.owner_wallet_id).pop() || {
          owner_wallet_id: val.owner_wallet_id,
          total_cost: 0,
        };

        o.total_cost += val.total_cost;
        acc.push(o);
        return acc;
      }, [])
      .filter((itm, i, a) => i === a.indexOf(itm));

    let expenses = sessions
      .reduce((calculated, session) => {
        let o = calculated.filter((obj) => obj.wallet_id === session.user_wallet_id).pop() || {
          wallet_id: session.user_wallet_id,
          total_cost: 0,
        };

        o.total_cost += session.total_cost;
        calculated.push(o);
        return calculated;
      }, [])
      .filter((itm, i, a) => i === a.indexOf(itm));
    return {
      consumptionkWh,
      session,
      cost,
      duration,
      user,
      revenues,
      expenses,
    };
  }
}

export default new SessionHelpers();
