class TimezoneHelper {
  getOffset() {
    let currentTime = new Date();
    let currentTimezone = currentTime.getTimezoneOffset();
    currentTimezone = (currentTimezone / 60) * -1;
    let gmt = "";
    if (currentTimezone !== 0) {
      gmt += currentTimezone > 0 ? " +" : " ";
      gmt += currentTimezone;
    }
    return gmt;
  }

  getNumberOffsetFromFullTimezone(full_timezone) {
    if (!full_timezone) return undefined
    
    const splittedOffset = (full_timezone || "").match(/[+-]\d{2}:\d{2}/)[0].split(":")
    if (splittedOffset.length === 0) return undefined

    const hour = parseFloat(splittedOffset[0])
    const minute = parseFloat(splittedOffset[1]) / 60
    return hour < 0 ? hour - minute : hour + minute
  }
}

export default new TimezoneHelper();
