import SortHelpers from "./SortHelpers";

class LogHelpers {
  filter(logs, filters) {
    if (!filters) return logs;
    if (filters.search) {
      if (!filters.enableRegexSearch) {
        let search = filters.search.toLowerCase();
        logs = logs.filter((log) => {
          let id = log.id.toString();
          let domain = log.domain.toLowerCase();
          let subdomain = log.subdomain.toLowerCase();
          let message = log.message.toLowerCase();
          if (id.indexOf(search) > -1 || domain.indexOf(search) > -1 || subdomain.indexOf(search) > -1 || message.indexOf(search) > -1) return logs;
        });
      } else {
        try {
          const search = new RegExp(filters.search, "");
          logs = logs.filter((log) => {
            const id = log.id.toString();
            const domain = log.domain;
            const subdomain = log.subdomain;
            let message = log.message;
            return id.match(search) || domain.match(search) || subdomain.match(search) || subdomain.match(message);
          });
        } catch (error) {
          console.log(error.message);
          logs = [];
        }
      }
    }

    if (filters.sort_direction_desc) logs.sort(SortHelpers.compareValues(filters.sort_by, "desc"));
    else logs.sort(SortHelpers.compareValues(filters.sort_by));

    return logs;
  }
}

export default new LogHelpers();
