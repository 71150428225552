const HOUR_TO_MINUTE = 60
const HOUR_TO_SECOND = 3600
const MINUTE_TO_SECOND = 60

export function hourToHourMinuteText(value) {
  const rawHour = parseFloat(value)
  const hourInMinutes = rawHour * HOUR_TO_MINUTE
  const hour = Math.floor(hourInMinutes / HOUR_TO_MINUTE)
  const minute = hourInMinutes % HOUR_TO_MINUTE
  const texts = []
  if (hour > 0) texts.push(`${hour} hour${hour > 1 ? 's' : ''}`)
  if (minute > 0) texts.push(`${minute} minute${minute > 1 ? 's' : ''}`)
  return texts.join(" ")
}

export function secondToHourMinuteText(value) {
  const seconds = parseFloat(value)
  const minute = Math.floor((seconds % HOUR_TO_SECOND) / HOUR_TO_MINUTE)
  const hour = Math.floor(seconds / HOUR_TO_SECOND)
  const texts = []
  if (hour > 0) texts.push(`${hour} hour${hour > 1 ? 's' : ''}`)
  if (minute > 0) texts.push(`${minute} minute${minute > 1 ? 's' : ''}`)
  return texts.join(" ")
}

export function hourToSeconds(value) {
  if (!value && value !== 0) return null
  const rawHour = parseFloat(value)
  return rawHour * HOUR_TO_SECOND
}

export function secondToHours(value, withDecimals = false) {
  if (!value && value !== 0) return null
  const rawSecond = parseInt(value)
  const hours = rawSecond / HOUR_TO_SECOND
  return withDecimals ? parseFloat(hours.toFixed(2)) : Math.floor(hours)
}

export function minuteToHour(value) {
  if (!value && value !== 0) return null
  const rawMinute = parseInt(value)
  return parseFloat((rawMinute / HOUR_TO_MINUTE).toFixed(2))
}

export function minuteToSeconds(value) {
  if (!value && value !== 0) return null
  const rawMinute = parseFloat(value)
  return rawMinute * MINUTE_TO_SECOND
}

export function hourAndMinuteIntToAmPm({ hour, minute, amPm }) {
  let textHour = (parseInt(hour) - (parseInt(hour) > 12 ? 12 : 0)).toString().padStart(2, "0")
  if (textHour === "00") textHour = "12"
  const textMinute = parseInt(minute).toString().padStart(2, "0")
  const textAmPm = amPm || (parseInt(hour) >= 12 ? "PM" : "AM")
  return { hour: textHour, minute: textMinute, amPm: textAmPm }
}

export function formatTwoDigitTime(time) {
  if (!time) return "00";
  if (time < 10) return "0" + time;
  return time;
}

export function getTimezoneOffset() {
  let currentTime = new Date();
  let currentTimezone = currentTime.getTimezoneOffset();
  currentTimezone = (currentTimezone / 60) * -1;
  let gmt = "";
  if (currentTimezone !== 0) {
    gmt += currentTimezone > 0 ? " +" : " ";
    gmt += currentTimezone;
  }
  return gmt;
}