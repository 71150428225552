class xmlHelpers {
  parse(rawXML, tagName = "response") {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(rawXML, "text/xml");
    const xmlResponse = xmlDoc.getElementsByTagName(tagName)[0].childNodes
    const parsedResponse = {}
    for (let i = 0; i < xmlResponse.length; i++) {
      const property = xmlResponse[i];
      if (["text", "#text"].includes(property.nodeName)) continue
      parsedResponse[property.nodeName] = property.childNodes[0] ? property.childNodes[0].nodeValue : ""
    }
    return parsedResponse
  }
}

export default new xmlHelpers();
