class RouteHelpers {
  isAdminUrl() {
    return !!window.location.href.match(/\/admin/);
  }
  isLocal() {
    return !window.location.href.match(/my./) && !window.location.href.match(/manage\./) && !window.location.href.match(/api\./);
  }
  isProduction() {
    return (
      !window.location.href.match(/localhost/) &&
      !window.location.href.match(/demo-contractor/) &&
      !window.location.href.match(/\/dev/) &&
      !window.location.href.match(/api-staging\./)
    );
  }
  isOwnerUrl() {
    return window.location.href.match(/\/owner/) || window.location.href.match(/manage./);
  }
}

export default new RouteHelpers();
