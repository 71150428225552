import axios from "axios";
import { Capacitor, CapacitorHttp } from "@capacitor/core";
import store from "@/store/store";
import { xmlHelpers, ApiHelpers } from "@/library/helpers";
import { v4 as uuidv } from "uuid";
class Api {
  // This function will execute the api
  runApi(method = "POST", url = "", payload = {}, headers = {}, httpRequestProvider = null) {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) return reject("Network Error");

      const config = {
        url,
        method,
        headers,
      };
      if (method == "POST") config.data = payload;
      else if (method == "GET") config.params = payload;

      if (!headers.hasOwnProperty("Accept")) config.headers["Accept"] = "application/json, text/plain, */*";
      if (!headers.hasOwnProperty("Content-Type")) config.headers["Content-Type"] = "application/json";
      else if (headers["Content-Type"] === "application/x-www-form-urlencoded" && Capacitor.isNativePlatform()) {
        config.data = new URLSearchParams(config.data).toString();
      }

      const token = localStorage.getItem("key");
      if (!!token) config.headers["X-jwt"] = token;

      let request;
      if (httpRequestProvider == "axios") request = axios.request(config);
      else if (httpRequestProvider == "capacitor-http") request = CapacitorHttp.request(config);
      else request = Capacitor.isNativePlatform() ? CapacitorHttp.request(config) : axios.request(config);

      const id = uuidv();
      store.commit("Global/setState", {
        apiCalls: [...store.state.Global.apiCalls, { url, id }],
      });
      request
        .then((res) => {
          if (res.status == 200) {
            const returns = {
              status: 200,
              data: null,
            };
            try {
              returns.data = JSON.parse(res.data);
            } catch (e) {
              returns.data = res.data;
            }
            resolve(returns.data);
          } else {
            const error_message = this.getErrorMessage(res.data, res.status);
            let error = {
              ...res,
              response: {
                data: error_message,
                status: res.status,
              },
            };
            reject(error);
          }
        })
        .catch((error) => {
          if (!error.response || ![400, 401, 500].includes(error.response.status)) {
            return reject(error);
          }
          error.response.data = this.getErrorMessage(error.response.data, error.response.status);
          reject(error);
        })
        .finally(() => {
          store.commit("Global/setState", {
            apiCalls: (store.state.Global.apiCalls || []).filter((call) => call.id !== id),
          });
        });
    });
  }
  getErrorMessage(message, status = 400) {
    let error_message = message;
    if (status === 400) {
      switch (message) {
        case "Plug is already in the system":
          error_message = "Device is already registered to a manager";
          break;
        case "User is not authorized to use this plug":
          error_message = "Access to this device is restricted. For access, please contact management.";
          break;
        case "Insufficient wallet fund":
          error_message = "Insufficient funds in wallet";
          break;
        case "Plug not found in the system":
          error_message = "Device does not exist";
          break;
        case "Plug not reachable":
          error_message = "Device not reachable";
          break;
        case `Username doesn't exist`:
          error_message = "User does not exist";
          break;
        case `Plug is disabled`:
          error_message = "Device has been disabled";
          break;
        case `Plug mode change failed`:
          error_message = `Failed to change device's power settings. Device maybe offline.`;
          break;
        case `The plug is already in use`:
          error_message = `This device is already in use`;
          break;
        case `Username exists`:
          error_message = `E-mail is already registered. Please try signing in`;
          break;
      }
    } else if (status === 401) {
      console.log("--- 401 ---");
      store.dispatch("Auth/redirectToLogin");
      // if (this.$route.name !== `${this.roleSetting.routePrefix}login`) this.$router.push({ name: `${this.roleSetting.routePrefix}login` });
    } else if (status === 500) {
      error_message = "Error 500. Please contact support using the 'Chat With Us' tab";
    }
    return error_message;
  }

  /** Authentication API */
  login(role, formData, headers = {}) {
    return this.runApi("POST", `${API_URL}/${role}/authenticate`, formData, headers, "capacitor-http");
  }
  signup(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/signup`, formData);
  }
  changePassword(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/change-password`, formData);
  }
  resetPassword(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/reset-password`, formData);
  }
  setPassword(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/set-password`, formData);
  }
  /** End Authentication API */

  /** Admin Related API */
  adminUser(formData) {
    let api_url = `${API_URL}/admin/user`;
    return this.runApi("POST", api_url, formData);
  }
  walletsRefund(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/wallets/refund`, formData);
  }
  adminOwner(formData) {
    let api_url = `${API_URL}/admin/owner`;
    return this.runApi("POST", api_url, formData);
  }
  plugSetExpiry(apiPrefix, formData) {
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug/set-expiry`, formData);
  }
  plugSetState(apiPrefix, formData) {
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug/set-state`, formData);
  }
  plugSetMode(apiPrefix, formData) {
    let parsedFormData = JSON.parse(formData);

    if (store.state.OwnerProxy.actAs) {
      parsedFormData = Object.assign({}, parsedFormData, ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs));
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug/set-mode`, JSON.stringify(parsedFormData));
  }
  /** End Admin Related API */

  /** Log API */
  getLog(formData) {
    const headers = { "x-log-token": "LettherebeloggoleberehtteL23" };
    return this.runApi("POST", `${LOG_API_URL}/get-log`, formData, headers);
  }
  lastLog(formData) {
    const headers = { "x-log-token": "LettherebeloggoleberehtteL23" };
    return this.runApi("POST", `${LOG_API_URL}/last-log`, formData, headers);
  }
  /** End Log API */

  /** Plugs or Device Related API */
  plug(apiPrefix, formData) {
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug`, formData);
  }
  plugs(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plugs`, formData);
  }
  plugUsers(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    const route = role == "admin" ? "admin/plug/users" : "owner/plug-users";
    return this.runApi("POST", `${API_URL}/${route}`, formData);
  }
  plugPaymentAccessPlans(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug-payment-access-plans`, formData);
  }
  plugDataplanConfig(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug/get-dataplan-config`, formData);
  }
  plugRequestSession(formData = {}) {
    return this.runApi("POST", `${API_URL}/plug/request-session`, formData);
  }
  plugSessionUpdate(formData = {}, headers = {}) {
    return this.runApi("POST", `${API_URL}/plug/session-update`, formData, headers);
  }
  plugBillingPaymentLog(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug/billing-payment-log`, formData);
  }
  plugPaymentAccessPlansUpdate(role, formData) {
    let prefix = "plug-payment-access-plan";
    if (role == "owner") prefix = "plug-payment-access-plans";

    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }

    return this.runApi("POST", `${API_URL}/${role}/${prefix}/update`, formData);
  }
  plugRegister(role, formData) {
    const endpoint = role == "admin" ? "admin/plug/register" : "owner/register-plug";
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${endpoint}`, formData);
  }
  plugSetAccess(role, access, formData) {
    let requestData = typeof formData === "string" ? JSON.parse(formData) : formData;
    if (store.state.OwnerProxy.actAs) {
      requestData = { ...requestData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug/set-access-${access}`, JSON.stringify(requestData));
  }
  plugSetPaymentWallet(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug/set-payment-wallet`, formData);
  }
  userPlugs(role, formData) {
    let api_url = `${API_URL}`;
    if (role == "owner") api_url += "/owner/user-plugs";
    else if (role == "admin") api_url += "/admin/owner/user-plugs";

    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${api_url}`, formData);
  }
  setUserAccessToPlug(role, is_grant, formData) {
    const grant = is_grant ? "give" : "revoke";

    let parsedFormData = JSON.parse(formData);

    if (store.state.OwnerProxy.actAs) {
      parsedFormData = Object.assign({}, parsedFormData, ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs));
    }
    return this.runApi("POST", `${API_URL}/${role}/${grant}-user-access-to-plug`, JSON.stringify(parsedFormData));
  }
  ownerPlugRenewService(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/owner/plug/renew-service`, formData);
  }
  plugSetDescription(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/plug/set-description`, formData);
  }
  plugPaymentAccessPlansCreate(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug-payment-access-plans/create`, formData);
  }
  plugSendCommand(role, formData = {}) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/plug/send-command`, formData);
  }
  userPlugAccess(formData) {
    return this.runApi("POST", `${API_URL}/user/plug-access`, formData);
  }
  plugGetAsyncToken(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/plug/get-async-token`, formData);
  }
  plugPostAsyncSessions(role, formData) {
    return this.runApi("POST", `${API_URL}/${role}/plug/post-async-sessions`, formData);
  }
  userPlugSetAutoStart(formData) {
    return this.runApi("POST", `${API_URL}/user/plug/set-auto-start`, formData);
  }
  adminPlugDecomission(formData) {
    return this.runApi("POST", `${API_URL}/admin/plug/decomission`, formData);
  }
  adminPlugReplace(formData) {
    return this.runApi("POST", `${API_URL}/admin/plug/replace`, formData);
  }
  adminManufacturedPlugFetch(formData) {
    return this.runApi("POST", `${API_URL}/admin/manufactured-plug/fetch`, formData);
  }
  adminPlugGetDataplanConfig(formData) {
    return this.runApi("POST", `${API_URL}/admin/plug/get-dataplan-config`, formData);
  }
  adminPlugSetDataplanConfig(formData) {
    return this.runApi("POST", `${API_URL}/admin/plug/set-dataplan-config`, formData);
  }
  /** End Plugs or Device Related API */

  /** Sessions Related API */
  ongoingSessions(prefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${prefix}/ongoing-sessions`, formData);
  }
  sessionLogs(role, formData) {
    let api_url = `${API_URL}`;
    if (role == "admin") api_url += "/admin/session";
    else api_url += `/${role}/sessions`;

    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }

    return this.runApi("POST", `${api_url}/log`, formData);
  }
  sessions(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/sessions`, formData);
  }
  sessionsEnd(formData) {
    let api_url = `${API_URL}/user/sessions/end`;
    return this.runApi("POST", api_url, formData);
  }
  sessionsStart(formData) {
    let api_url = `${API_URL}/user/sessions/start`;
    return this.runApi("POST", api_url, formData);
  }
  ownerKillSession(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/owner/kill-session`, formData);
  }
  /** End Sessions Related API */

  /** Wallet Related API */
  wallets(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/wallets`, formData);
  }
  adminWallet(formData) {
    return this.runApi("POST", `${API_URL}/admin/wallet`, formData);
  }
  walletsCreate(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/wallets/create`, formData);
  }
  walletsSetDescription(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/wallets/set-description`, formData);
  }
  walletTransactions(role, formData) {
    const apiPrefix = role == "admin" ? "admin/wallet" : `${role}/wallets`;
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/transactions`, formData);
  }
  walletTopupPaypal(formData) {
    if (store.state.OwnerProxy.actAs) {
      const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;
      formData = JSON.stringify({ ...currentValue, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) });
    }
    return this.runApi("POST", `${API_URL}/wallet/topup/paypal`, formData);
  }
  walletTopupRazorpay(formData) {
    if (store.state.OwnerProxy.actAs) {
      const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;
      formData = JSON.stringify({ ...currentValue, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) });
    }
    return this.runApi("POST", `${API_URL}/wallet/topup/razorpay`, formData);
  }
  walletTopupMobikwik(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/wallet/topup/mobikwik`, formData);
  }
  walletRazorpayCreateOrder(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/wallet/razorpay/create-order`, formData);
  }
  walletMobikwikCreateOrder(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/wallet/mobikwik/create-order`, formData);
  }
  walletsSetParams(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/wallets/set-params`, formData);
  }
  walletsRequestPayout(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/wallets/request-payout`, formData);
  }
  /** End Wallet Related API */

  /** Mobile App Related API */
  userPushNotification(mode, formData) {
    return this.runApi("POST", `${API_URL}/user/push-notification/${mode}`, formData);
  }
  /** End Mobile App Related API */

  /** Public Profile Related API */
  ownerGetPublicProfile(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/owner/get-public-profile`, formData);
  }
  ownerSetPublicProfile(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/owner/set-public-profile`, formData);
  }
  /** End Public Profile Related API */

  /** General API */
  sendFeedback(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/send-feedback`, formData);
  }
  version(params = {}) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("GET", `${API_URL}/version`, params);
  }
  /** End General API */

  /** Settings API */
  getPrefs(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/get-prefs`, formData);
  }
  setPrefs(apiPrefix, formData) {
    if (store.state.OwnerProxy.actAs) {
      const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;
      formData = JSON.stringify({ ...currentValue, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) });
    }
    return this.runApi("POST", `${API_URL}/${apiPrefix}/set-prefs`, formData);
  }
  adminOwnerRename(formData) {
    return this.runApi("POST", `${API_URL}/admin/owner/rename`, formData);
  }
  /** End Settings API */

  /** KillSession API */
  adminPlugsessionkill(formData) {
    return this.runApi("POST", `${API_URL}/admin/plug/session/kill`, formData);
  }
  /** End KillSession API */

  /** Owner Proxies API */
  proxySet(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/owner-proxy/set`, formData);
  }
  proxyProxies(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    if (store.state.Admin.managerview) {
      formData = ApiHelpers.appendValueToPayload(formData, "ownerUsername", store.state.Admin.managerview?.owner_username);
    }
    return this.runApi("POST", `${API_URL}/${role}/owner-proxy/proxies`, formData);
  }
  proxyOwners(role, formData, includeActAs = true) {
    if (includeActAs && store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    if (store.state.Admin.managerview) {
      formData = ApiHelpers.appendValueToPayload(formData, "ownerProxyUsername", store.state.Admin.managerview?.owner_username);
    }
    return this.runApi("POST", `${API_URL}/${role}/owner-proxy/owners`, formData);
  }
  proxyRemove(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/owner-proxy/remove`, formData);
  }
  /** End Owner Proxies API */

  /** Admin Userview Set-Password API  */
  adminUserSetPassword(formData) {
    return this.runApi("POST", `${API_URL}/admin/user/set-password`, formData);
  }
  /** Admin Userview Set-Password API  */
  /** Mobikwik */
  mobikwikMakeChecksum(formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/mobikwik/make-checksum`, formData);
  }
  mobikwikCheckExistingUser(mobileNumber) {
    let payload = JSON.stringify({
      endpoint: "/querywallet",
      params: {
        action: "existingusercheck",
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 500,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });

    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 500,
    //       statuscode: 0,
    //       statusdescription: "User Exists",
    //       status: "SUCCESS",
    //       emailaddress: "arya.bawanta@gmail.com",
    //       nonzeroflag: "n"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCreateUser(mobileNumber, email = "", otp = "") {
    let payload = JSON.stringify({
      endpoint: "/createwalletuser",
      params: {
        cell: mobileNumber,
        email,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 502,
        otp,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });

    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 502,
    //       statuscode: 0,
    //       statusdescription: "User Created",
    //       status: "SUCCESS",
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikGenerateOTP(mobileNumber, amount = 0) {
    const payload = JSON.stringify({
      endpoint: "/otpgenerate",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 504,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 504,
    //       statuscode: 0,
    //       statusdescription: "Message Sent to xxxxxxxx",
    //       status: "SUCCESS",
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikGenerateToken(mobileNumber, amount, otp) {
    let payload = JSON.stringify({
      endpoint: "/tokengenerate",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 507,
        otp,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 507,
    //       statuscode: 0,
    //       statusdescription: "Token Generated",
    //       status: "SUCCESS",
    //       token: "e099cd677d2a4581aa2828e1c3991b01"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCheckBalance(mobileNumber, token = "") {
    const payload = JSON.stringify({
      endpoint: "/userbalance",
      params: {
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 501,
        token,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 501,
    //       statuscode: 0,
    //       statusdescription: "Balance Amount",
    //       status: "SUCCESS",
    //       balanceamount: 0
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikAddMoneyToWalletAndDebit(mobileNumber, token = "", orderid = "", amount = 0, redirecturl = "https://api.plugz.io") {
    const payload = JSON.stringify({
      endpoint: "/addmoneytowalletanddebit",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        orderid,
        redirecturl,
        token,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 503,
    //       statuscode: 0,
    //       statusdescription: "Amount Debited",
    //       status: "SUCCESS",
    //       balanceamount: 2111,
    //       debitedamount: 1,
    //       refId: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikProcessDebit(mobileNumber, token = "", orderid = "", txntype = "debit", amount = 0, comment = "Debit") {
    const payload = JSON.stringify({
      endpoint: "/debitwallet",
      params: {
        amount,
        cell: mobileNumber,
        comment,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 503,
        orderid,
        token,
        txntype,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 503,
    //       statuscode: 0,
    //       statusdescription: "Amount Debited",
    //       status: "SUCCESS",
    //       balanceamount: 2111,
    //       debitedamount: 1,
    //       refId: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCheckStatus(orderid = "") {
    let payload = JSON.stringify({
      endpoint: "/checkstatus",
      params: {
        mid: "plugzioid",
        orderid,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response, "wallet");
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       statuscode: 0,
    //       orderid: "12345",
    //       statusmessage: "success",
    //       amount: 1,
    //       refId: "1234567890",
    //       ordertype: "payment"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikTokenRegenerate(cell, token) {
    let payload = JSON.stringify({
      endpoint: "/tokenregenerate",
      params: {
        cell,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 507,
        token,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       statuscode: 0,
    //       status: "SUCCESS",
    //       messagecode: 507,
    //       statusdescription: "Token Generated",
    //       token: "f4f72b7f7693484cb8bdc068f8cc14b3",
    //       checksum: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  /** END Mobilwik */

  /** Load Balancing Related API */
  loadBalancingCollectives(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collectives`, formData);
  }
  loadBalancingCollectiveAddPlug(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/add-plug`, formData);
  }
  loadBalancingCollectiveCreate(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/create`, formData);
  }
  loadBalancingCollectiveDelete(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/delete`, formData);
  }
  loadBalancingCollectiveGet(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/get`, formData);
  }
  loadBalancingCollectiveRemovePlug(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/remove-plug`, formData);
  }
  loadBalancingCollectiveUpdate(role, formData) {
    if (store.state.OwnerProxy.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(store.state.OwnerProxy.actAs) };
    }
    return this.runApi("POST", `${API_URL}/${role}/load-balancing/collective/update`, formData);
  }
  /** End Load Balancing API */
}

export default new Api();
