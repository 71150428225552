import Vue from "vue"

class TimeHelpers {
  getTotalDuration(duration, format = ":mm:ss") {
    let is_negative = duration < 0;
    duration = Math.abs(duration);
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);
    let seconds = Math.floor(duration % 60);
  
    let formattedDuration = "";
  
    formattedDuration += hours.toString().padStart(2, "0") + ":";
    formattedDuration += minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
  
    return (is_negative ? "-" : "") + formattedDuration;
  }
  getDuration(duration, format = ":mm:ss") {
    let is_negative = duration < 0;
    duration = Math.abs(duration);
    let d = Vue.moment.duration(duration);
    let h = Math.floor(d.asHours());
    if (h === 0) h = "00";
    let s = h + Vue.moment.utc(duration).format(format);

    return (is_negative ? "-" : "") + s;
  }
  lapse(start, end, format = ":mm:ss") {
    let ms = end - start;
    return this.getDuration(ms, format);
  }
  ago(timestamp, timezone = null) {
    let duration = Vue.moment().unix() - timestamp;
    if (duration < 60) {
      return "Just now";
    } else if (duration < 3600) {
      let mins = Math.ceil(duration / 60);
      return mins > 1 ? `${mins} mins ago` : `${mins} min ago`;
    } else if (duration < 86400) {
      let hours = Math.ceil(duration / 3600);
      return hours > 1 ? `${hours} hrs ago` : `${hours} hr ago`;
    } else {
      let date = Vue.moment.unix(timestamp);
      if (!!timezone) date = date.tz(timezone);
      return date.format("DD-MMM-YYYY");
    }
  }
}

export default new TimeHelpers();
