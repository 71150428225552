import { saveAs } from "file-saver";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { FileOpener } from "@capacitor-community/file-opener";
class FileHelpers {
  constructor() {
    this.isApp = Capacitor.isNativePlatform();
  }

  convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }
  async download(string, filename, type = "text/html") {
    let blob = this.stringToBlob(string, type);
    if (!this.isApp) return saveAs(blob, filename);

    return await Filesystem.writeFile({
      path: filename,
      data: string,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    }).then(() => {
      Filesystem.getUri({
        path: filename,
        directory: Directory.Documents,
      }).then((res) => {
        console.log("savedFile", res);
        FileOpener.open({
          filePath: res.uri,
          contentType: "text/csv"
        })
          .then(() => console.log("File is opened"))
          .catch((error) => console.log("Error openening file", error));
      });
    });
  }
  stringToBlob(string, type = "text/html") {
    return new Blob([string], { type });
  }
}

export default new FileHelpers();
