export function getEnvironment() {
    const currentUrl = window.location.href;

    if (currentUrl.match(/^capacitor:\/\/localhost#\//)) {
        if (currentUrl.match(/\/owner/)) {
            return "Developer/Mobile/Manager";
        } else {
            return "Developer/Mobile/User";
        }
    }
    if (currentUrl.match(/localhost/)) {
        if (currentUrl.match(/\/owner/)) {
            return "Developer/Manager";
        } else if (currentUrl.match(/\/admin/)) {
            return "Developer/Admin";
        } else {
            return "Developer/User";
        }
    } else if (currentUrl.match(/\/demo-contractor/)) {
        if (currentUrl.match(/\/owner/)) {
            return "Staging/Manager";
        } else if (currentUrl.match(/\/admin/)) {
            return "Staging/Admin";
        } else {
            return "Staging/User";
        }
    } else if (currentUrl.match(/my\.plugz\.io/)) {
        return "Production/User";
    } else if (currentUrl.match(/manage\.plugz\.io/)) {
        return "Production/Manager";
    }
    
    return "Production";
}  

