
import { isDecimalExceedLimit } from "."

export function formatNumber(num) {
  if (!num) return 0;
  var parts = num.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}
export function formatAmount(amount, currency = null) {
  return currency ? new Intl.NumberFormat('en-IN', { minimumSignificantDigits: 2, style: "currency", currency }).format(amount) : formatNumber(parseFloat(amount).toFixed(2));
}
export function formatCost(cost, currency = null) {
  return (cost == null || cost === 0) ? "FREE" : formatAmount(cost, currency);
}
export function formatRate(rate, unit = "h", currency = null) {
  if (rate == null || rate === 0 ) return "FREE"
  if (unit != "" && unit != null) unit = " / " + unit;
  return formatAmount(parseFloat(rate), currency) + unit;
}

export function formatDecimal(param) {
  if (!param) return this.inputs[param] = 0.00;
  if (!this.inputs[param]) this.inputs[param] = 0;
  this.inputs[param] = parseFloat(this.inputs[param]).toFixed(2);
}

export function stringToDecimal(string, decimalPlaces = 2) {
  if (!string) return "0.00"
  return parseFloat(string).toFixed(decimalPlaces);
}

export function floorNumberToTwoDecimal(number) {
  if (!number) return 0.00
  return Math.floor(number*100)/100; 
  
}