class ApiHelpers {
  getApiPrefix(role, activeAdminView = false) {
    if (role !== "admin") return role;
    return activeAdminView === "managerview" ? "admin/owner" : activeAdminView === "userview" ? "admin/user" : "admin";
  }
  getActAsPayload(actAsUsername = null) {
    return actAsUsername ? { actAsOwnerUsername: actAsUsername } : {};
  }
  appendValueToPayload(payload, key, value) {
    return !!value ? { ...payload, [key]: value } : payload;
  }
  getUsernamePayloadOfViewAs(activeAdminView = false, managerview = null, userview = null) {
    if (!activeAdminView) return {}
    if (activeAdminView === "managerview") return managerview ? { ownerUsername: managerview.username } : {}
    if (activeAdminView === "userview") return userview ? { username: userview.username } : {}
  }
}

export default new ApiHelpers();
