import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import VueNumeralFilter from "vue-numeral-filter";
import LightTimeline from "vue-light-timeline";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VTooltip from "v-tooltip";
import VueAgile from "vue-agile";
import "./settings";
import "vue-good-table/dist/vue-good-table.css";
import VueGoodTable from "vue-good-table";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { Filesystem } from "@capacitor/filesystem";
import { App as CapacitorApp } from "@capacitor/app";
import VueMeta from "vue-meta";
import sessions from "@/mixins/sessions";
import { DeviceHelpers, log } from "./library/helpers";
import 'core-js'
import VueIntercom from 'vue-intercom'
import RouteHelpers from "./library/helpers/RouteHelpers";
import VueGtm from '@gtm-support/vue2-gtm'

let isMobileApplication = Capacitor.isNativePlatform();
const role = RouteHelpers.isAdminUrl() ? "admin" : RouteHelpers.isOwnerUrl() ? "owner" : "user";

console.log(`base: ${window.UI_URL}`);
console.log(`host: ${window.location.host}`);
console.log(`api: ${window.API_URL}`);
console.log(`log api: ${window.LOG_API_URL}`);
console.log("is capacitor:", Capacitor.isNativePlatform());

// Vue set
sync(store, router);
Vue.config.productionTip = false;
Vue.use(Vuetify, {
  theme: {
    primary: "#424242",
    accent: "#2b8a2b",
  },
});
Vue.use(LightTimeline);
Vue.use(VueNumeralFilter);
Vue.use(VueMoment, { moment });
Vue.use(VTooltip);
Vue.use(VueAgile);
Vue.use(VueGoodTable);
Vue.use(VueMeta);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID })

// Admin is not having google tag, so please handle that later
if (role !== "admin") {
  const gtmId = role === "owner" ? process.env.VUE_APP_MANAGER_GTM_ID : process.env.VUE_APP_USER_GTM_ID
  Vue.use(VueGtm, {
    id: gtmId,
    vueRouter: router
  })
}

// Axios set
window.axios = axios;
axios.defaults.timeout = 30000;

// For easier testing
window.device_parse_serial_number = DeviceHelpers.parseSerialNumber;

// Boot main app
const app = new Vue({
  mixins: [sessions],
  data: {
    status: false,
    reset: null,
    isMobileApplication
  },
  methods: {
    init() {
      this.status = true;
      this.isMobileApplication = isMobileApplication
    },
    setLink(evt) {
      this.$store.dispatch("setUniversalLink", evt);
      let resetPasswordUrl = evt.url.match(/reset-password-verification/) || false;
      const isVerifyMobikwik = evt.url.match(/\?verify-mobikwik=true_/) || false;
      if (isVerifyMobikwik) {
        const [baseUrl, query] = evt.url.split(/\?verify-mobikwik=true_/)
        const [orderId, phone, token] = query.split("_")

        location.replace(`${baseUrl}${baseUrl.includes("/demo-contractor") ? "#/" : "#/"}${this.$store.getters["Auth/getRoleSetting"].urlPrefix}verify-mobikwik?orderId=${orderId}&phone=${phone}&token=${token}`)
      } else if (!!evt.params.external_id) {
        localStorage.setItem("external-id", evt.params.external_id);
        this.$router.push(`/activate-device/${evt.params.external_id}`);
      } else if (resetPasswordUrl) {
        localStorage.setItem("reset-password", evt.url);
        this.$nextTick(() => {
          this.$router.push({ path: `/forgot-password/verify` });
        });
      } else if (evt.params.payload) {
        localStorage.setItem("verify", evt.url);
        this.$nextTick(() => {
          this.$router.push({ path: `/login/verify` });
        });
      } else {
        const hash = evt.hash.slice(1);
        this.$router.push({ path: hash });
      }
    },
    setFirebaseToken(token) {
      this.$store.dispatch("setFirebaseToken", token);
    },
    setFirebaseMessage(message) {
      this.$store.dispatch("firebaseMessage", message);
    },
    resume() {
      this.$store.dispatch("onResume");
    },
    pause() {
      this.$store.dispatch("onPause");
    },
  },
  mounted() {
    // Handle url for web apps
    if (!isMobileApplication) {
      this.init();
      const url = new URL(document.URL);
      const mappedUrl = {
        url: url.href,
        scheme: "https",
        host: url.host,
        path: url.pathname,
        params: url.href.split("?")[1] ? Object.fromEntries(new URLSearchParams(url.href.split("?")[1])) : {},
        hash: url.hash,
      };
      log("URL", mappedUrl)
      this.setLink(mappedUrl);
    }
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

if (isMobileApplication) {
  const mApp = {
    initialize() {
      this.bindEvents();
    },
    bindEvents() {
      document.addEventListener("deviceready", this.onDeviceReady, false);
      document.addEventListener("resume", this.onResume, false);
      document.addEventListener("pause", this.onPause, false);
    },
    async onDeviceReady() {
      console.log("device is ready");
      app.init();

      // handling deeplinking
      CapacitorApp.addListener("appUrlOpen", function(event) {
        const url = new URL(event.url);
        const mappedUrl = {
          url: url.href,
          scheme: "https",
          host: url.host,
          path: url.pathname,
          params: Object.fromEntries(url.searchParams),
          hash: url.hash,
        };
        console.log("open url", mappedUrl);
        app.setLink(mappedUrl);
      });

      // handling push notification with capacitor
      console.log("Initializing Capacitor Push Notification");

      let FilesystemPermission = await Filesystem.checkPermissions();
      console.log(FilesystemPermission)
      if (FilesystemPermission.publicStorage === "prompt") {
        FilesystemPermission = await Filesystem.requestPermissions();
      }
      console.log("request notification permission", pushNotificationPermission);

      // handling push notification with capacitor
      console.log("Initializing Capacitor Push Notification");

      let pushNotificationPermission = await PushNotifications.checkPermissions();
      if (pushNotificationPermission.receive === "prompt") {
        pushNotificationPermission = await PushNotifications.requestPermissions();
      }
      console.log("request notification permission", pushNotificationPermission);
      if (pushNotificationPermission.receive === "granted") {
        console.log("Notification permission granted");
        PushNotifications.register();
      } else {
        console.error("Notification permission not granted");
      }

      /** Listeners */
      PushNotifications.addListener("registration", (token) => {
        console.log("Push registration success, token: " + token.value);
        app.setFirebaseToken(token.value);
      });

      PushNotifications.addListener("registrationError", (error) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener("pushNotificationReceived", (notification) => {
        console.log("Push received: " + JSON.stringify(notification));

        if (app.$store.state.Session.processedDevices.map(v => v.identifier).includes(notification.data.plugIdentifier)) {
          app.$store.commit("Session/removeProcessedDevices", notification.data.plugIdentifier)
          app.getSessions()
        }
        app.setFirebaseMessage(notification);
      });

      PushNotifications.addListener("pushNotificationActionPerformed", ({ notification: { data } }) => {
        console.log("Push action performed: " + JSON.stringify(data));
        const sessionEvent = data.event
        const sessionId = data.sessionId
        let queryString = "status=on"
        if (sessionEvent === "session-ended") queryString = `openSessionIdDialog=${sessionId}`
        app.$router.push(`/sessions?${queryString}`);
      });
    },
    onResume() {
      app.resume();
    },
    onPause() {
      app.pause();
    },
  };

  mApp.initialize();
} else {
  let firebasePath = `${window.location.href.match(/demo-contractor/) ? '/demo-contractor' : ''}/static/firebase-messaging-sw.js`
  Notification.requestPermission().then(async (permission) => {
    const { default: firebaseMessaging } = await import("./firebase")
    const { getToken, onMessage } = await import("firebase/messaging")
    if (permission === 'granted') {
      const serviceWorkerRegistration = await navigator.serviceWorker.register(firebasePath);
      getToken(
        firebaseMessaging,
        {
          vapidKey: process.env.FIREBASE_VAPID_KEY,
          serviceWorkerRegistration
        }
      ).then(token => {
        app.setFirebaseToken(token);
        
        onMessage(firebaseMessaging, ({ data }) => {
          console.log("[firebase] message received", data)

          if (app.$store.state.Session.processedDevices.map(v => v.identifier).includes(data.plugIdentifier)) {
            app.$store.commit("Session/removeProcessedDevices", data.plugIdentifier)
            app.getSessions()
          }
        });
      }).catch((err) => {
        console.log('An error occurred while retrieving firebase token. ', err);
      });
    }
  })

}
