import { OwnerProxyFeaturePermissions } from "../../constants"
import store from "../../store/store"

class OwnerProxyHelpers {
  isHaveAccessToFeature(feature) {
    const featurePermissions = OwnerProxyFeaturePermissions[feature];
    if (!featurePermissions) return false;

    const activeActAsUser = store.getters["OwnerProxy/activeActAsUser"];
    // if no active act as user, it means the user is the owner itself
    if (!activeActAsUser) return true;

    const userPermissions = store.getters["OwnerProxy/activeActAsUser"]?.permissions;

    // null is for view-only access, string is not exist now but just in case it exist, it will returns here
    if (userPermissions === null || typeof userPermissions === "string") return featurePermissions.includes(userPermissions)

    return userPermissions.some((permission) => featurePermissions.includes(permission));
  }

  getRightsText(text) {
    return text?.includes("general-modify") ? "Full" : "View"
  }
}

export default new OwnerProxyHelpers();
