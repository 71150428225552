const indiaBoxCoordinates = {
  minLatitude: 7.445976,
  maxLatitude: 36.525479,
  minLongitude: 67.053819,
  maxLongitude: 97.085998,
}

class LocationHelpers {
  getCountryByLatitudeAndLongitude(latitude, longitude) {
    if (
      indiaBoxCoordinates.minLatitude <= latitude
      && indiaBoxCoordinates.maxLatitude >= latitude
      && indiaBoxCoordinates.minLongitude <= longitude
      && indiaBoxCoordinates.maxLongitude >= longitude
    ) {
      return {
        code: "IN",
        name: "India",
      };
    }

    // currently we only support India
    return null
  }
}

export default new LocationHelpers();
