import moment from "moment";

class MobikwikHelpers {
  getSavedTokens() {
    return JSON.parse(localStorage.getItem("__mobikwik_tokens__") || "{}") || {}
  }
  updateSavedTokens(phone, value) {
    const tokens = this.getSavedTokens()
    if (!value) delete tokens[phone]
    if (!!value) {
      let expiry = moment().add(365, "days").unix()
      const foundToken = tokens[phone] || null
      if (foundToken) expiry = foundToken.expiry || moment().add(365, "days").unix()
      tokens[phone] = {
        token: value,
        expiry
      }
    }
    localStorage.setItem("__mobikwik_tokens__", JSON.stringify(tokens))
  }
}

export default new MobikwikHelpers();
