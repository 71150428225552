import DeviceHelpers from "./DeviceHelpers";
import SortHelpers from "./SortHelpers";
import Vue from "vue"

class PlugHelpers {
  filter(plugs, filters) {
    if (!filters) return plugs;
    if (filters.search) {
      if (!filters.enableRegexSearch) {
        let search = filters.search.toLowerCase();
        plugs = plugs.filter((outlet) => {
          let identifier = outlet.identifier ? outlet.identifier.toLowerCase() : '';
          let description = outlet.description ? outlet.description.toLowerCase() : '';
          let accessPlan = outlet.access_plan ? outlet.access_plan.toLowerCase() : '';
          let remaining = outlet.remaining ? outlet.remaining.toLowerCase() : '';
          let start = outlet.start ? outlet.start.toLowerCase() : '';
          let expiry = outlet.expiry ? outlet.expiry.toLowerCase() : '';
          let auto = outlet.auto ? outlet.auto.toLowerCase(): '';
          let service_tier_id = typeof outlet.service_tier_id === "string" ? outlet.service_tier_id.toLowerCase() : '';
          let serial_no = outlet.serial_no ? outlet.serial_no.toLowerCase(): '';
    
          if (
            identifier.indexOf(search) > -1 || 
            description.indexOf(search) > -1 || 
            accessPlan.indexOf(search) > -1 || 
            remaining.indexOf(search.trim()) > -1 || 
            start.indexOf(search) > -1 || 
            expiry.indexOf(search) > -1 || 
            auto.indexOf(search.trim()) > -1 || 
            service_tier_id.indexOf(search) > -1 || 
            serial_no.indexOf(search) > -1
          )
           return plugs
        });
      } else {
        try {
          const search = new RegExp(filters.search, "");
          plugs = plugs.filter((outlet) => {
            const identifier = outlet.identifier || '';
            const description = outlet.description || '';
            const accessPlan = outlet.access_plan || '';
            const remaining = outlet.remaining || '';
            const start = outlet.start || '';
            const expiry = outlet.expiry || '';
            const auto = outlet.auto || ''; 
            const service_tier_id = (outlet.service_tier_id || '').toString();
            const serial_no = outlet.serial_no || '';

            return identifier.match(search) || description.match(search) || accessPlan.match(search) || remaining.match(search) || start.match(search) || expiry.match(search) || auto.match(search) || service_tier_id.match(search) || serial_no.match(search);
          });
        } catch (error) {
          console.log(error.message);
          plugs = [];
        }
      }
    }

    if (filters.sort_direction_desc) plugs.sort(SortHelpers.compareValues(filters.sort_by, "desc"));
    else plugs.sort(SortHelpers.compareValues(filters.sort_by));

    if (filters.hasOwnProperty("state") && filters.state !== "all") plugs = plugs.filter((plug) => plug.active_state == filters.state);
    if (filters.hasOwnProperty("status") && filters.status !== "all") plugs = plugs.filter((plug) => filters.status === plug.plugmode || (filters.status > 2 && plug.plugmode > 2));
    if (filters.hasOwnProperty("communication_type") && filters.communication_type !== "all")
      plugs = plugs.filter((plug) => filters.communication_type === plug.communication_type);
    if (filters.hasOwnProperty("power_setting") && filters.power_setting !== "all") plugs = plugs.filter((plug) => filters.power_setting === plug.plugmode);
    return plugs;
  }
  format(plug, variables) {
    if (!plug) return null;

    plug.active = !!variables.sessions ? !!variables.sessions.find((a) => a.plug_id === plug.id) : false;
    plug.active_state = plug.active || plug.plugmode === 1;

    plug.plan = null;
    if (!!plug.plan_id) plug.plan = !!variables.plans ? variables.plans.find((plan) => plan.id === plug.plan_id) : null;
    if (plug.plan && typeof plug.plan.params == "string") plug.plan.params = JSON.parse(plug.plan.params);

    plug.access_settings = plug.ispublic ? "Public" : "Private";
    plug.access_plan = plug.ispublic && plug.plan ? plug.plan.params.description : "";

    plug.mode = "Always Off";
    plug.color = "#f44336";
    if (plug.plugmode == 1) {
      plug.mode = "Always On";
      plug.color = "#00acc1";
    } else if (plug.plugmode == 2) {
      plug.mode = "Smart";
      plug.color = "#FFA500";
    } else if (plug.plugmode >= 3) {
      plug.mode = "Bluetooth";
      plug.color = "#000";
    }

    const start = Vue.moment.unix(plug.start_timestamp);
    const expiry = Vue.moment.unix(plug.expiry_timestamp);
    const today = Vue.moment();
    const diff = expiry.diff(today, "days");
    plug.auto = plug.payment_wallet_id ? "ON" : "OFF";
    plug.auto_class = plug.payment_wallet_id ? "green--text" : "orange--text";
    plug.start = start.format("DD-MMM-YYYY hh:mm:ss A");
    plug.expiry = expiry.format("DD-MMM-YYYY hh:mm:ss A");
    plug.diff = diff;

    plug.expired = "bill-expired";
    plug.remaining = "Disabled";
    plug.remaining_raw = 0;
    if (plug.state != 0) {
      plug.expired = diff <= 7 ? "bill-warning" : diff <= 30 ? "bill-notify" : "";
      plug.remaining_raw = plug.diff;
      plug.remaining = plug.diff < 1 ? `${Math.abs(plug.diff)} Days Expired` : plug.diff + " Days";
    }

    const { communication, communication_type } = DeviceHelpers.parseSerialNumber(plug.serial_no || "") || {}
    plug.communication = communication;
    plug.communication_type = communication_type

    plug.logs = [];
    plug.expanded = false;
    plug.busy = false;

    return plug;
  }
}

export default new PlugHelpers();
