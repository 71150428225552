const KILOWATT_TO_WATT = 1000

export function kwhToWh(value) {
  if (!value && value !== 0) return null
  return value * KILOWATT_TO_WATT
}

export function whToKwh(value, decimals = 2) {
  if (!value && value !== 0) return null
  return parseFloat((value / KILOWATT_TO_WATT).toFixed(decimals))
}