class DeviceHelpers {
  constructor() {
    this.STABLE_AMPERAGE_CONVERTER = 0.8
    
    this.deviceModes = {
      "always_off": 0,
      "always_on": 1,
      "smart": 2,
      "bluetooth": 3,
    }
    this.models = {
      plugzio_ocpp: "Plugzio OCPP",
      ocpp: "3rd Party OCPP",
      universal: "Universal",
      global: "Global",
      integrated: "Integrated",
    }
    this.communicationTypes = {
      threeG: "3G",
      wifi: "WiFi",
      ltem: "LTE-M"
    }
  }

  parseSerialNumber(serial_number) {
    const model_regexs = {
      plugzio_ocpp: /\d{3}H\d{2}V\d/,
      ocpp: /\d{3}P\d{2}V\d/,
      universal: /\d{3}[CLWF]\d{2}V\dU/,
      global: /\d{3}[CLWF]\d{2}V\dG/,
      integrated: /\d{3}[CLWF]\d{2}V\d/,
    }

    const communication_regexs = {
      threeG: /\d{3}C/,
      wifi: /\d{3}[WF]/,
      ltem: /\d{3}L/,
      ocpp: /\d{3}P/,
      plugzio_ocpp: /\d{3}H/,
    }

    let model = null
    for (const key in model_regexs) {
      if (serial_number.match(model_regexs[key])) {
        model = key
        break
      }
    }

    let communication = null
    for (let key in communication_regexs) {
      if (serial_number.match(communication_regexs[key])) {
        if (key === "ocpp") key = "wifi" // ocpp always has WiFi communication type
        if (key === "plugzio_ocpp") key = "wifi" // plugziocpp has Ethernet/Cellular/WiFi communication type, however for now we only support WiFi

        communication = key
        break
      }
    }

    const voltage = serial_number.match(/\d{3}/)
    const current = serial_number.match(/[A-Z]\d{2}[A-Z]/)
    const version = serial_number.match(/V[0-9]+/)

    return {
      model: this.models[model],
      communication: this.communicationTypes[communication],
      voltage: voltage ? voltage[0] : "",
      current: current ? current[0].substring(1, 3) : "",
      version: version ? version[0].replace("V", "") : "",
      communication_type: communication == "wifi" ? "WiFi" : "Cellular",
      get power() {
        const power = parseInt(this.voltage) * parseInt(this.current) / 1000;
        return isNaN(power) ? "" : power.toFixed(2);
      }
    }
  }
  parseInventoryStatus(status) {
    const texts = ["Unknown", "Assembled", "In Certification", "Certified", "Ready-to-Ship", "Shipped", "Decommissioned", "Demo"]

    return texts[status] ? texts[status] : "Invalid Value"
  }
}

export default new DeviceHelpers();
