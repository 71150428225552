export function isJSON(str) {
  try {
    const obj = JSON.parse(str);

    return obj && typeof obj === "object" && obj !== null;
  } catch (err) {
    return false;
  }
}

export function log(...args) {
  if (!!window.show_logs || !!process.env.VUE_APP_SHOW_LOGS) {
    console.log(...args);
  }
}

export * from "./formatter";
export * from "./filters";
export * from "./sort";
export * from "./time";
export * from "./timeBased";
export * from "./paymentPlan";
export * from "./wattage";
export * from "./string";
export * from "./input";
export * from "./sleep";
export { default as validators } from "./validators";

export { default as DeviceHelpers } from "./DeviceHelpers";
export { default as camelCaseToCapitalize } from "./camelCaseToCapitalize";
export { default as LocationHelpers } from "./LocationHelpers";
export { default as TextHelpers } from "./TextHelpers";
export { default as xmlHelpers } from "./xmlHelpers";
export { default as MobikwikHelpers } from "./MobikwikHelpers";
export { default as ApiHelpers } from "./ApiHelpers";
export { default as OwnerProxyHelpers } from "./OwnerProxyHelpers";
export { default as SortHelpers } from "./SortHelpers";
export { default as FileHelpers } from "./FileHelpers";
export { default as SessionHelpers } from "./SessionHelpers";
