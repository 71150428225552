
import { DAYS_CONSTANT } from "@/constants"
import { hourAndMinuteIntToAmPm } from "@/library/helpers"
import Vue from "vue"

export function timeBasedGetText({ type = "time", values = {} }) {
  const { hour, minute, amPm } = hourAndMinuteIntToAmPm(values || { hour: 0, minute: 0 })
  if (type === "time") {
    values.hour = hour
    values.minute = minute
    values.amPm = amPm
  }
  const texts = {
    time: `${values.hour || "..."}:${values.minute || "..."} ${values.amPm || "..."}`,
    day: DAYS_CONSTANT[values.weekday],
    date: `${Vue.moment(`2040-${values.month}-${values.day}`).format("MMMM Do")} at ${hour}:${minute} ${amPm}`
  }

  return texts[type]
}